<template>
	<div class="concent">
		<Header></Header>
		<div class="warper">
			<div class="header">
				<el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
					<el-sub-menu index="1">
						<template #title>
							<router-link to="/Master">
								<el-icon>
									<Menu />
								</el-icon>
								<span>竞赛管理</span>
							</router-link>
						</template>
						<el-menu-item index="1-1">
							<router-link to="/Teachers">
								<el-icon>
									<EditPen />
								</el-icon>报名管理
							</router-link>
						</el-menu-item>
						<!-- <el-menu-item index="1-2">
							<router-link to="/Info">
								<el-icon>
									<Message />
								</el-icon>信息管理
							</router-link>
						</el-menu-item> -->
					</el-sub-menu>
				</el-menu>
				<div class="w-bottom"></div>
			</div>
			<div class="main">
				<!-- <el-breadcrumb :separator-icon="ArrowRight">
					<el-breadcrumb-item :to="{ path: '/backHome' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-for="item in breadcrumbList" :key="item.path" :to="{path:item.path}">
						{{item.title}}
					</el-breadcrumb-item>
				</el-breadcrumb> -->
				<router-view />
			</div>
			<div class="footer">
				<!-- 尾部 -->
				<Footer></Footer>
			</div>
		</div>
	</div>
</template>


<script>
import Header from "./header.vue";
import Footer from "./footer.vue";
import { Menu, EditPen, Message,ArrowRight } from '@element-plus/icons-vue'
export default {
	name: 'home',
	props: {
		// msg: String
	},
	components: {
		Header, Footer, Menu, EditPen, Message,ArrowRight
	},
	data() {
		return {
			menuList: [],
			ArrowRight,
			indexBreadcrumbs:[]
		}
	},
	watch: {
  		$route() {
			// this.breadcrumbList();
  		}
 },
	created() {
		console.log(this.$route);
		this.menuList = this.$route.matched[0].children
		console.log(this.$route.matched);
	},
	// computed: {
	// // 动态面包屑
	// 	breadcrumbList() {
	// 	let breadcrumbs = []
	// 	// 获取的菜单列表
	// 	let menuList = this.menuList
	// 	// 获取面包屑路由菜单对象
	// 	for (let i = 0; i < menuList.length; i++) {
	// 			// 如果没有二级菜单
	// 		if(this.$route.matched[0].name == 'backHome') {
	// 			breadcrumbs = []
	// 		} else {
	// 			breadcrumbs.push(menuList[i])
	// 			break
	// 		}
			
	// 		}
	// 		console.log(breadcrumbs); 
	// 	return breadcrumbs
		
	// }
	// },
	methods: {

	},
}
</script>

<style scoped lang="scss">
:deep(.el-sub-menu__title) {
	border-bottom: solid #ccc 1px
}

:deep(.el-menu-item) {
	border-bottom: solid #ccc 1px
}


.router-link-active {
  text-decoration: none;
}

@import './index.scss';
</style>
